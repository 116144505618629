import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/lingo2-account/auth.service';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import { PlatformService } from '@core/services/platform.service';
import { BillingApiCurrencyRateResponseDto } from '@lingo2-billing-sdk/models';
import { DestroyableComponent } from '@models/destroyable.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService extends DestroyableComponent {
  private currencies = this.register(new BehaviorSubject<BillingApiCurrencyRateResponseDto[]>([]));
  public currencies$ = this.currencies.asObservable();

  constructor(
    protected authService: AuthService,
    protected billingV2Service: BillingV2Service,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  public getCurrenciesRate() {
    return this.billingV2Service.getCurrencyRate();
  }

  public getRateForCurrency(
    currencies: BillingApiCurrencyRateResponseDto[],
    currencyCode: string,
  ): BillingApiCurrencyRateResponseDto {
    if (!currencies.length) {
      return;
    }
    return currencies.find((currency) => currency.currency === currencyCode.toUpperCase());
  }
}
